import React, { Dispatch, SetStateAction } from 'react'
import { BgSpacer, Wrapper } from './styles'
import { sendDatalayerEvent } from 'src/hooks/useDataLayer/utilsUseDataLayer'
import { Link } from 'gatsby'

type Props = {
  isMobile: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  oneLink: string;
}

const YourDollarsWorthMore = ({ isMobile, oneLink, setIsOpen }: Props) => {
  return (
    <Wrapper>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-lg-6 order-1 order-lg-2'>
            <BgSpacer />
          </div>
          <div className='col-12 col-lg-5 col-xl-6 order-2 order-lg-1 my-md-4 my-lg-0'>
            <h2 className='fs-24 fs-md-32 fs-xl-40 lh-28 lh-md-38 lh-xl-48 fw-600 text-grayscale--500 mb-4'>
              <span className='d-xl-block'>Seus dólares valem</span> mais com a Global<span className='d-xl-block'> Account Inter</span>
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-18 lh-md-20 lh-xl-22 text-grayscale--500'>
              Receba seus pagamentos do Google Adsense em reais e carregue a sua
              <Link to='/pra-voce/global-account/' className='fw-600'>{' '}Global Account{' '}</Link>
              em dólares.
            </p>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-18 lh-md-20 lh-xl-22 text-grayscale--500'>
              Faça compras no débito e saques em moedas internacionais, <span className='fw-600'>sem taxas de conversão.</span>
            </p>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-18 lh-md-20 lh-xl-22 text-grayscale--500'>
              Aproveite para <span className='fw-600'>investir ou movimentar seus dólares</span> pelo Super App. Mais que uma forma de receber seus pagamentos do Adsense, a Global Account do Inter é completa.
            </p>
            {
              isMobile ? (
                <a
                  href={oneLink}
                  target='_blank'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100 mt-4'
                  rel='noreferrer'
                  title='Abrir Global Account'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Seus dólares valem mais com a Global Account Inter',
                      element_action: 'click button',
                      element_name: 'Abrir Global Account',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  Abrir Global Account
                </a>
              ) : (
                <button
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4'
                  onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Seus dólares valem mais com a Global Account Inter',
                    element_action: 'click button',
                    element_name: 'Abrir Global Account',
                    })
                  }}
                >
                  Abrir Global Account
                </button>
              )
            }
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default YourDollarsWorthMore
