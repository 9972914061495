import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContainerCarousel = styled.section`
  h2, h3 {
    font-family: 'Sora';
  }

  padding: 40px 0;
  @media ${device.desktopLG} {
    padding: 80px 0;
  }
  @media ${device.desktopXL} {
    padding:
    
  }

  .react-multi-carousel-dot--active button {
    background: ${orange.base};
  }
`
