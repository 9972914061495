import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Wrapper = styled.div`
  z-index: 99;
  background-color: ${white};
  border-radius: 32px;
  padding: 24px;

  min-height: 400px;

  @media ${device.desktopLG} {
    padding: 40px
  }

  input {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  button {
    max-width: 100%;
  }
`
