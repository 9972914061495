import React, { useLayoutEffect, useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import CarouselComoReceber from './sections/carousel-como-receber/_index'

import pageContext from './pageContext.json'
import Hero from './sections/hero/_index'
import useWidth from 'src/hooks/window/useWidth'
import YourDollarsWorthMore from './sections/your-dollars-worth-more/_index'
import GlobalAccountAdvantages from './sections/global-account-advantages/_index'
import Faq from './sections/faq/_index'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import QRcodeGA from './assets/global-account-adsense-open-account.png'
import QRcodeReceber from './assets/google-adsense-pagamento-qrcode.png'
import useDomReady from 'src/hooks/window/useDomReady'

const WIDTH_MD = 768

const GlobalAdsensePayment = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isOpenGlobalAccount, setIsOpenGlobalAccount ] = useState(false)
  const domReady = useDomReady()
  const width = useWidth(200)
  const [ isMobile, setIsMobile ] = useState(true)

  const oneLink = 'https://intergo.app/9fedeb80'
  const openGlobalAccLink = 'https://bancointer.go.link/globalAccount?adj_t=15esrix9&adj_fallback=https%3A%2F%2Fgoi[…]lAccount&adj_adgroup=crossborder&adj_creative=globalaccount '

  useLayoutEffect(() => {
    setIsMobile(WIDTH_MD > width)
  }, [ width ])

  const openModalReceber = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<h2 class="fs-24 fs-600 text-grayscale--500"><span class="d-block">Escaneie o QR Code e</span>  acesse o <span class="text-orange--extra">Super App!</span></h2>'
        instructions='Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar em ”Abrir conta completa e gratuita”. É rápido e fácil!'
        qrCode={QRcodeReceber}
      />
    </Modal>
  )

  const openModalGA = domReady && (
    <Modal isModalOpen={isOpenGlobalAccount} setIsModalOpen={setIsOpenGlobalAccount} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpenGlobalAccount}
        setIsModal={setIsOpenGlobalAccount}
        title='<h2 class="fs-24 fs-600 text-grayscale--500"><span class="d-block">Escaneie o QR Code e</span>  acesse o <span class="text-orange--extra">Super App!</span></h2>'
        instructions='Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar em ”Abrir conta completa e gratuita”. É rápido e fácil!'
        qrCode={QRcodeGA}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModalGA}
      {openModalReceber}
      <Hero setIsOpen={setIsOpen} oneLink={oneLink} isMobile={isMobile} />
      <CarouselComoReceber isMobile={isMobile} oneLink={oneLink} setIsOpen={setIsOpen} />
      <YourDollarsWorthMore isMobile={isMobile} oneLink={openGlobalAccLink} setIsOpen={setIsOpenGlobalAccount} />
      <GlobalAccountAdvantages />
      <Faq />
    </Layout>
  )
}

export default GlobalAdsensePayment
