/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/interface-name-prefix */

import { ElementAction, IDataLayer } from './typesUseDataLayer'
declare global {
  interface Window {
    dataLayer: Record<string, any>;
  }
}

export const sendDatalayerEvent = (event: IDataLayer) => {
  if (window && window.dataLayer) {
    window.dataLayer.push(event)
  }
  return window.dataLayer
}

export const HandleClickLinkButton = (section: string, elementAction: ElementAction, elementName: string, redirectURL?: string) => {
  const dataLayerCtaSimularAgora: IDataLayer = {
    event: 'ga_event_body',
    params: {
      section: section,
      element_action: elementAction,
      element_name: elementName,
      element_previous: 'null',
      step: 'null',
      c_page: window.location.href,
      redirect_url: redirectURL,
    },
  }
  sendDatalayerEvent(dataLayerCtaSimularAgora)
}

// Criei essa funcão temporaria, para liberar essa LP, com as regras novas da doc no confluence.
export const handleClickLinkButtonAbrirContaInterCartao = (section: string, elementAction: ElementAction, elementName: string, redirectURL?: string) => {
  const dataLayerCtaSimularAgora: IDataLayer = {
    event: 'ga_event_body',
    params: {
      section: section,
      element_action: elementAction,
      element_name: elementName,
      element_previous: 'null',
      step: 'null',
      c_page: window.location.href,
      redirect_url: redirectURL || 'null',
    },
  }
  sendDatalayerEvent(dataLayerCtaSimularAgora)
}
