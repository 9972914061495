import React, { Dispatch, SetStateAction, useState } from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import CarouselAdsenseJSON from '../../assets/data/global-account-adsense-carousel.json'

import { ContainerCarousel } from './styles'
import { sendDatalayerEvent } from 'src/hooks/useDataLayer/utilsUseDataLayer'

export type CarouselProps = {
  title: string;
  image: string;
  altText: string;
}

type Props = {
  isMobile: boolean;
  oneLink: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const CarouselComoReceber = ({ isMobile, oneLink, setIsOpen }: Props) => {
  const [ carouselIndex, setCarouselIndex ] = useState(0)

  return (
    <ContainerCarousel>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-16 lh-20 fs-lg-24 lh-lg-30 fw-600 text-grayscale--500'>
              Como receber do Google Adsense pelo Inter
            </h2>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(nextSlide: number) => setCarouselIndex(nextSlide)}
            >
              {CarouselAdsenseJSON.map((item: CarouselProps, index: number) => (
                <div className='row' key={index}>
                  <div className='col-12'>
                    <h3
                      className='text-grayscale--500 fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 mb-3'
                      dangerouslySetInnerHTML={ { __html: item.title } }
                    />
                  </div>
                  <div className='col-12 d-md-none'>
                    <img src={item.image} alt={item.altText} height='300px' />
                  </div>
                </div>
          ))}
            </DefaultCarousel>
            {
              isMobile ? (
                <a
                  href={oneLink}
                  target='_blank'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100 mt-4'
                  rel='noreferrer'
                  title='Abrir Global Account'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      section_name: 'Como receber do Google Adsense pelo Inter',
                      element_action: 'click button',
                      element_name: 'Abrir Global Account',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  Receber dinheiro
                </a>
              ) : (
                <button
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100 mt-4'
                  onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Como receber do Google Adsense pelo Inter',
                    element_action: 'click button',
                    element_name: 'Abrir Global Account',
                    })
                  }}
                >
                  Receber dinheiro
                </button>
              )
            }
          </div>
          <div className='col-6 d-none d-md-block'>
            <img
              src={CarouselAdsenseJSON[carouselIndex].image}
              alt={CarouselAdsenseJSON[carouselIndex].altText}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    </ContainerCarousel>
  )
}

export default CarouselComoReceber
