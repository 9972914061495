import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${grayscale[100]};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mobile-cartoes/image.webp');
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;

  h2 {
    font-family: 'Sora';
  }

  padding: 40px 0;
  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/768-cartoes/image.webp');
  }
  @media ${device.desktopLG} {
    padding: 80px 0;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1024-cartoes/image.webp');
    background-position: right;
  }
  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/1440-cartoes/image.webp');
    padding: 96px 0;
  }
  
`

export const BgSpacer = styled.div`
  height: 320px;

  @media ${device.tablet} {
    height: 480px;
  }
`
