import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Wrapper } from './styles'
import { CalculatorUS } from 'src/components/USCalculatorForm/status/sent/CalculatorUS'
import { ICalculatorUS } from 'src/components/USCalculatorForm/status/sent/CalculatorUS/types/types'
import HeroForm from '../hero-form/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Button } from '@interco/inter-ui/components/Button'
import { initTheme, Theme } from '@interco/inter-ui'

type Props = {
  isMobile: boolean;
  oneLink: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const Hero = ({ isMobile, oneLink, setIsOpen }: Props) => {
  const [ showForm, setShowForm ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  function handleCustomLinkClick () {
    if (!isMobile) {
      setIsOpen(true)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Receba pagamentos do Google Adsense no mesmo dia com o Super App',
        element_action: 'click button',
        element_name: 'Já sou correntista, quero simular no app',
      })
    } else {
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Receba pagamentos do Google Adsense no mesmo dia com o Super App',
        element_action: 'click button',
        element_name: 'Já sou correntista, quero simular no app',
        redirect_url: oneLink,
      })
    }
  }

  const customButtom = () => (
    <Button
      type='submit'
      form='heroForm'
      className='mt-3'
      fullWidth
      onClick={() => setShowForm(true)}
    >
      Receber dinheiro
    </Button>
  )

  const customLink = () => (
      isMobile ? (
        <a
          href={oneLink}
          className='fw-700 fs-12 text-underline'
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => handleCustomLinkClick()}
        >
          Já sou correntista, quero simular no app
        </a>
      ) : (
        <a
          className='fw-700 fs-12 text-underline'
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => handleCustomLinkClick()}
        >
          Já sou correntista, quero simular no app
        </a>
      )
  )

  const text: Pick<ICalculatorUS, 'textData'> = {
    textData: {
      title: 'Qual valor a ser recebido do Google AdSense?',
      exchangeText: 'Câmbio',
      description: [ '*IOF e outros impostos podem incidir de acordo com o seu perfil de operação.', '** Os valores simulados tem validade de 5 minutos.' ],
      button: 'Receber dinheiro',
      CustomButtom: customButtom,
      CustomLink: customLink,
    },
  }

  return (
    <Wrapper>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xxl-5'>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-38 lh-xl-48 fw-600 text-white'>
              Receba pagamentos do Google Adsense no mesmo dia com o Super App
            </h1>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-18 lh-lg-20 lh-xl-22 mb-md-0 text-white'>
              A melhor conta digital para receber os seus pagamentos do Google Adsense. Sem taxas extras, apenas spread. Receba em qualquer dia da semana, a qualquer hora. Rápido, simples e seguro.
            </p>
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            {
              showForm
                ? (<HeroForm />)
                : (
                  <CalculatorUS textData={text.textData} filterBrazil>
                    <CalculatorUS.Title />
                    <CalculatorUS.Sender />
                    <CalculatorUS.ExchangeTaxes />
                    <CalculatorUS.Destination />
                    <CalculatorUS.Description />
                    <CalculatorUS.Link />
                    <CalculatorUS.Button />
                  </CalculatorUS>
                )
            }
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Hero
