import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import BackgroundLG from '../../assets/global-account-adsense-hero-bg-lg.png'
import BackgroundSM from '../../assets/global-account-adsense-hero-bg-sm.png'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${orange.extra};
  background-image: url(${BackgroundSM});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  h1 {
    font-family: 'Sora';
  }

  padding: 40px 0;
  @media ${device.tablet} {
    background-image: url(${BackgroundLG});
    background-size: contain;
    background-position: right;
  }
  
  @media ${device.desktopLG} {
    padding: 80px 0;
  }
  
  @media ${device.desktopXL} {
    padding: 96px 0;
    min-height: 85vh;
  }
  
`
