import React, { useState } from 'react'
import { Wrapper } from './styles'
import { Input, COMMON_MASKS } from '@interco/inter-ui/components/Input'
import { Controller, UseFormMethods, useForm } from 'react-hook-form'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import axios from 'axios'
import * as URLS from 'src/config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Button } from '@interco/inter-ui/components/Button'
interface IFormValues {
  nome: string;
  email: string;
  CpfCnpj: string;
  aceite: boolean;
}

const HeroForm = () => {
  const [ isLoading, setLoading ] = useState(false)
  const [ isSent, setIsSent ] = useState(false)
  const [ isSentError, setIsSentError ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const { register, handleSubmit, errors, control, watch }: UseFormMethods<IFormValues> = useForm<IFormValues>()

  const watchAceite = watch('aceite')

  const sendValues = async (data: IFormValues) => {
    setLoading(true)
    const formattedData = {
      ...data,
      campanha: 'Simulacao Adsense Global Account',
      CpfCnpj: data.CpfCnpj.replace(/\D/g, ''),
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formattedData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setLoading(false)
      setIsSent(true)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Receba pagamentos do Google Adsense no mesmo dia com o Super App',
        element_name: 'Receber dinheiro',
        element_action: 'click button',
      })
    } catch (e) {
      setLoading(false)
      setIsSentError(true)
    }
  }

  return (
    <Wrapper>
      <div className='col-12'>
        <h2 className='text-grayscale--500 fs-14 fs-lg-18'>Insira suas informações e tenha acesso ao melhor do SuperApp</h2>
      </div>
      {
        isSentError ? (
          <div className='text-center mt-5'>
            <OrangeDsIcon icon='clear' size='XL' color='#F56A50' />
            <p className='fs-16 fs-lg-22 fw-600 text-grayscale--500 mt-5'>Ocorreu algum erro, por favor tente novamente.</p>
          </div>
        ) : ''
      }
      {
        isSent ? (
          <div className='text-center mt-5'>
            <OrangeDsIcon icon='checkfill' size='XL' color='#00AA4F' />
            <p className=' fa-16 fs-lg-22 fw-600 text-grayscale--500 mt-5'>Formulário enviado com sucesso!</p>
          </div>
        )
        : ''
      }
      {
        !isSent && !isSentError ? (
          <form onSubmit={handleSubmit(sendValues)} id='heroForm'>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <Input
                  ref={register({
                required: 'Campo obrigatório',
              })}
                  name='nome'
                  placeholder='Insira seu nome completo'
                  id='name'
                  label='Nome:'
                  style={{ width: '100%' }}
                  infoText={errors.nome?.message}
                  error={!!errors.nome}
                />
              </div>
              <div className='col-12 col-lg-6'>
                <Input
                  ref={register({
                required: 'Campo obrigatório',
              })}
                  name='CpfCnpj'
                  placeholder='Insira apenas números'
                  id='CpfCnpj'
                  label='CPF:'
                  mask={COMMON_MASKS.CPF}
                  style={{ width: '100%' }}
                  infoText={errors.CpfCnpj?.message}
                  error={!!errors.CpfCnpj}
                />
              </div>
              <div className='col-12'>
                <Input
                  ref={register({
                required: 'Campo obrigatório',
              })}
                  name='email'
                  placeholder='email@email.com'
                  id='email'
                  type='email'
                  label='E-mail:'
                  style={{ width: '100%' }}
                  infoText={errors.email?.message}
                  error={!!errors.email}
                />
              </div>
              <div className='col-12 d-flex'>
                <Controller
                  control={control}
                  name='aceite'
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  )}
                />
                <p className='fs-12 lh-15 mb-0 mt-1'>
                  Declaro que concordo com a
                  <a
                    className='fw-700 ml-n-2'
                    href='https://inter.co/politica-de-privacidade/privacidade/'
                    target='_blank' rel='noreferrer'
                  > Política de Privacidade{' '}
                  </a>
                  e autorizo o Inter a entrar em contato por e-mail.
                </p>
              </div>
              <div className='col-12 mt-3'>
                <Button
                  fullWidth
                  type='submit'
                  form='heroForm'
                  isLoading={isLoading}
                  disabled={!watchAceite || isLoading}
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        ) : ''
      }
    </Wrapper>
  )
}

export default HeroForm
